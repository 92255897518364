// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  getStoreId: 7,
  firebaseConfig : {
    apiKey: "AIzaSyDhUaF8cATN5lmIZsxvD_BETcR4oCcNrtE",
    authDomain: "unraitte-go.firebaseapp.com",
    projectId: "unraitte-go",
    storageBucket: "unraitte-go.appspot.com",
    messagingSenderId: "843535393549",
    appId: "1:843535393549:web:1e5dc4de0e41167f1e0644",
    measurementId: "G-709K7DEH2C"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
